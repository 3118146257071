// Libs.
import React, { useState } from 'react';
import styles from './app-rewards-landing.module.scss';
import { useImageStaticQuery } from '../../hooks/useImagesStaticQuery';
import Logo from '../../images/longos-logo.svg';
import Img from 'gatsby-image';
import TyrCardBarcode from '../tyr-card/tyr-card-barcode';
import { useStateValue } from '../../store/state';
import EarnRewardUserPoints from '../earn-rewards/earn-reward-user-points';
import ManageUserPoints from '../earn-rewards/manage-user-points';
import { get } from 'lodash';
import TyrCardboxMobile from '../tyr-card/tyr-cardbox-mobile';

const AppRewardLanding = () => {
  const [{ customer }] = useStateValue();
  const [state] = useStateValue();
  return (
    <div>
      <div className={styles.appRewardsLandingContainer}>
        <h1 className={styles.earnRewardsUserPointsTitle}>Hi, {state.customer.FirstName}!</h1>
        {get(customer, 'PointsBalance', -1) >= 0  && (
          <>
            <TyrCardboxMobile customer={customer} />
            <div className={styles.appRewardsLandingPoints}>
              <h2 className={styles.appRewardsLandingPointsTitle}>Your Points</h2>
              <EarnRewardUserPoints customer={state.customer} />
              <ManageUserPoints points={customer.PointsBalance} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AppRewardLanding;
