import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/layout/AppLayout';
import SEO from '../../components/layout/seo';
import AppRewards from '../../components/app/app-rewards';
import data from '../../mocks/app-reward';
import { useStateValue } from '../../store/state';
import { get, isEmpty } from 'lodash';
import AppRewardLanding from '../../components/app/app-rewards-landing';
import UserLoginMobile from '../../components/user/user-login-mobile';
import NavigationDrawer, { useDrawer } from '../../hooks/useNavigationDrawer';
import UserRegister from '../../components/user/user-register';
import AppRewardsAddCard from '../../components/app/app-rewards-add-card';
import UserResetPassword from '../../components/user/user-reset-password';
import { ValidateCodePasswordReset } from '../../components/app/validate-code-password-reset';
import UserResetPasswordConfirmed from '../../components/user/user-reset-password-confirmed';
import locale from '../../locale/locale';
import { UserSetLocation } from '../../store/user/action-creators';
import { getViewingPlatform } from '../../libs/getViewingPlatform';

const EarnRewards = ({ location }) => {
  const { iOS } = getViewingPlatform();
  const [state, dispatch] = useStateValue();
  const [isLoggedIn, setUserLoggedIn] = useState(-1);
  const [isLoginDrawer, setLoginDrawer] = useState(false);
  const [isRegisterDrawer, setRegisterDrawer] = useState(false);
  const [resetPasswordState, setResetPasswordState] = useState({ email: '', token: '' });
  const [drawers, closeDrawer] = useDrawer();
  const drawer = {
    setLoginDrawer,
    setRegisterDrawer,
    isRegisterDrawer,
    setResetPasswordState,
  };
  const closeUserLogin = () => {
    setLoginDrawer(false);
    closeDrawer(false, false);
  };

  const changeRegisterDrawer = (status) => {
    setLoginDrawer(false);
    setRegisterDrawer(status);
  };
  useEffect(() => {
    const query = {};
    const search = get(location, 'search', '');
    const searchParams = new URLSearchParams(search);
    if (searchParams.get('latitude') && searchParams.get('longitude')) {
      query.latitude = searchParams.get('latitude');
      query.longitude = searchParams.get('longitude');
    }
    if (!isEmpty(query)) {
      dispatch(UserSetLocation(query));
    }
  }, [location]);
  useEffect(() => {
    if (isEmpty(state.user.access_token)) {
      setUserLoggedIn(0);
    } else {
      setUserLoggedIn(1);
    }
  }, [get(state, 'user.access_token')]);

  return (
    <AppLayout updatePoints={true}>
      <SEO title="Earn Rewards" keywords={[`gatsby`, `application`, `react`]} />
      {isLoggedIn === 1 && (
        <>
          {iOS && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
          {state.customer.CardNumber ? <AppRewardLanding /> : <AppRewardsAddCard />}
        </>
      )}
      {isLoggedIn === 0 && (
        <>
          {iOS && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
          <AppRewards
            setDrawer={{ login: setLoginDrawer, register: setRegisterDrawer }}
            cta={data.appReward}
          />
        </>
      )}
      <NavigationDrawer
        header={'Rewards'}
        drawerStatus={
          isLoginDrawer || drawers.includes('userLogin') || drawers.includes('userLoginWelcome')
        }
        onClose={
          drawers.includes('userLogin') || drawers.includes('userLogingWelcome')
            ? closeUserLogin
            : setLoginDrawer
        }
      >
        <UserLoginMobile
          drawer={{ doLogin: closeUserLogin, doRegister: changeRegisterDrawer }}
          isRegistered={drawers.includes('userLoginWelcome')}
        />
      </NavigationDrawer>
      <NavigationDrawer
        header={'Rewards'}
        drawerStatus={isRegisterDrawer}
        onClose={setRegisterDrawer}
      >
        <UserRegister drawer={drawer} onClose={setRegisterDrawer} currentPage={'rewards'} />
      </NavigationDrawer>
      <NavigationDrawer header={'Rewards'} drawerStatus={drawers.includes('resetPassword')}>
        <UserResetPassword drawer={drawer} />
      </NavigationDrawer>
      <NavigationDrawer
        header={''}
        menuHide={true}
        drawerStatus={drawers.includes('verifyAccountReset')}
      >
        <ValidateCodePasswordReset userEmail={resetPasswordState.email} drawer={drawer} />
      </NavigationDrawer>
      <NavigationDrawer header={'Rewards'} drawerStatus={drawers.includes('passwordConfirmed')}>
        <UserResetPasswordConfirmed
          pageData={locale.changePasswordPage}
          token={resetPasswordState.resetToken}
        />
      </NavigationDrawer>
    </AppLayout>
  );
};

export default EarnRewards;
