// Libs.
import React, { useContext } from 'react';
import styles from "./app-rewards.module.scss";
import {useImageStaticQuery} from "../../hooks/useImagesStaticQuery";
import Img from "gatsby-image";
import { AppContext } from '../layout/AppLayout';

const AppRewards = ({ cta, setDrawer = false }) => {
  const app = useContext(AppContext);
  return (
    <div className={styles.appRewardWrapper} data-app={app}>
      <div className={styles.appRewardContainer}>
        <h1 className={styles.appRewardTitle} dangerouslySetInnerHTML={{ __html: cta.title }}/>
        <div className={styles.appRewardDescription}>{cta.description}</div>
        <div className={styles.appRewardButtonsContainer}>
          <button className={styles.appRewardButtonRegister} onClick={() => {setDrawer && setDrawer.register(true)}}>{cta.signUp}</button>
          <button className={styles.appRewardButtonSingIn} onClick={() => {setDrawer && setDrawer.login(true)}} >{cta.signIn}</button>
        </div>
      </div>
    </div>
  );
};

export default AppRewards;
